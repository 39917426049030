import { createStore } from 'vuex';
import { generateLeadId, generateVisitingDay } from '@/helpers/lead';
import { transformFormIntoMautic } from '@/helpers/api';
import env from '@/helpers/env';
import axios from 'axios';


export const formData = {
  // details
  fair: '',
  lead_id: '',
  visiting_day: '',
  contact_creator: '',
  // contact
  title: '',
  salutation: '',
  first_name: '',
  last_name: '',
  status: '',
  tel: '',
  email: '',
  business_card_front: '',
  business_card_back: '',
  // company
  company: '',
  sector: '',
  company_size: '',
  country: '',
  postal_code: '',
  // machine interests
  machines: [],
  // additional
  priority: '',
  additional: [],
  follow_up_actions: [],
  attachment1: '',
  attachment2: '',
  notice: '',

  submitted: false,

  // user
  user_id: 0,
};

export const formPreFills = {
  // All the fields that should be prefilled
  // are to be maintained here.
  // TODO: Client should be able to change this value on their own.
  // We should add a form in Drupal and provide the values via API.
  fair: 'IFFA 2025',
};

export default {
  namespaced: true,
  state: {
    leads: [],
    current: JSON.parse(JSON.stringify(formData)),
  },
  mutations: {
    SET(state, { key, value }) {
      state[key] = value;
    },
    UPDATE(state, lead) {
      const leadIndex = state.leads.findIndex(l => l.lead_id === lead.lead_id);
      state.leads[leadIndex] = lead;
      state.current = lead;
    },
    CREATE(state, lead) {
      lead.lead_id = generateLeadId();
      if (!lead.visiting_day || !lead.visiting_day === '') {
        lead.visiting_day = generateVisitingDay();
      }
      state.leads.push(lead);
      state.current = lead;
    },
    SET_SUBMITTED(state, leadId) {
      const lead = state.leads.find(l => l.lead_id === leadId);
      const leadIndex = state.leads.findIndex(l => l.lead_id === leadId);
      lead.submitted = true;
      state.leads[leadIndex] = lead;
      state.current = lead;
    },
  },
  actions: {
    async submitLeadToMautic({ commit, dispatch }, lead) {
      await dispatch('startLoading', 'mautic', { root: true });
      let errors = [];
      let errorDetails = [];
      const transformed = transformFormIntoMautic(lead);

      const mautic = env('mautic_api');
      const mauticUser = env('mautic_user');
      const mauticPassword = env('mautic_password');

      const auth = mauticUser && mauticPassword ? {
        username: mauticUser,
        password: mauticPassword,
      } : null;

      const response = await axios.post(`${mautic}contacts/new`, transformed, {
        auth,
      }).catch(async (e) => {
        await dispatch('endLoading', 'mautic', { root: true });
        const details = e.response.data.errors[0].details;
        errors = Object.keys(details);
        errorDetails = errors.map((err) => `[${err}] ${details[err][0]}`);
      });

      if (typeof response === 'undefined') {
        await dispatch('endLoading', 'mautic', { root: true });
      }
      if (response && response.status === 201) {
        commit('SET_SUBMITTED', lead.lead_id);
      }

      await dispatch('endLoading', 'mautic', { root: true });

      return { errors, errorDetails };
    },
    newLead({ commit }) {
      commit('SET', { key: 'current', value: JSON.parse(JSON.stringify(formData)) });
    },
    loadLead({ commit, getters }, leadId) {
      commit('SET', { key: 'current', value: getters.getLeadById(leadId) });
    },
    async saveOrCreate({ state, rootGetters, commit }, lead) {
      // does listId exist on new given data?
      const leadId = lead?.lead_id ? lead.lead_id : false;
      // add current user_id to lead
      lead.user_id = rootGetters['auth/getCurrentUser'].id;

      if (!!leadId) {
        // if yes update
        commit('UPDATE', lead);
      } else {
        // if not create a new one
        commit('CREATE', lead);
      }
    },
    prefillLead({ state, commit }, data) {
      commit('SET', { key: 'current', value: { ...state.current, ...data } });
    },
    deleteLead({ state, commit }, leadId) {
      const filteredLeads = state.leads.filter(lead => {
        return lead.lead_id !== leadId;
      });
      commit('SET', { key: 'leads', value: filteredLeads });
    },
  },
  getters: {
    getLeadsFromCurrentUser(state, getters, rootState, rootGetters) {
      return state.leads.filter(item => item.user_id === rootGetters['auth/getCurrentUser'].id);
    },
    getLeadById: (state, getters) => leadId => {
      return getters.getLeadsFromCurrentUser.find(({ lead_id }) => lead_id === leadId);
    },
  },
};
